import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { OrderItemV2 } from "../AccountOrderDetail/OrderShipments";

import {
  PICKUP_CODE,
  SHIPPING_CODE,
  axios,
  getErrorMessage,
  getSdkURL,
  useFormatDate,
} from "@ultracommerce/react-storefront/global";
import {
  clearCart,
  getEligibleOrderFulfillmentMethods,
  getAllPickupLocations,
  setPickupDateToOrderFulfillment,
  addPickupLocationToOrderFulfillment,
  clearOrderData,
  removeOrderItem,
  updateOrderItemQuantity,
  // applyPromoCodeToOrder,
  removePromoCodeFromOrder,
  changeFulfillmentOnOrder,
  addShippingAddressUsingAccountAddressToOrderFulfillment,
  addNewAddressAndAttachAsShippingOnOrderFulfillment,
  addShippingMethodToOrderFulfillment,
  addShippingAddressToOrderFulfillment,
} from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { SlatwalApiService } from "@ultracommerce/react-storefront/global";
import { OrderSummary } from "../../Checkout/OrderSummary";
import { QuoteToolbar } from "./QuoteToolbar";
import { FulfillmentList } from "../../Checkout/Fulfilment/FulfillmentList";
import {
  Button,
  PickupLocationDetails,
} from "@ultracommerce/react-storefront/global/src/components";
import { Modal } from "../../Modal/Modal";

const QuoteDetailV2 = React.memo((props) => {
  const { quoteDetail } = props;
  const quoteStatus = quoteDetail.orderStatusType.typeCode;

  const componentMap = {
    qstDraft: <QuoteDetailDraft {...props} />,
    qstRejected: <QuoteDetailClarification {...props} />,
    qstPendingReview: <QuoteDetailViewOnly {...props} />,
    qstOrderEntryRequestUpdated: <QuoteDetailViewOnly {...props} />,
    qstApproved: <QuoteDetailViewOnly {...props} />,
    qstRejectedQuote: <QuoteDetailViewOnly {...props} />,
  };

  const QuoteDetailComponent = useMemo(() => {
    return componentMap[quoteStatus] || null;
    // eslint-disable-next-line
  }, [quoteStatus, props]);

  return <div className=" mt-3">{QuoteDetailComponent}</div>;
});

const QuoteDetailDraft = (props) => {
  const { quoteDetail, updateQuote } = props;
  const { t } = useTranslation();
  const {
    CartModule,
    CommonModule: { Overlay },
  } = useElementContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eligibleFulfillmentMethods, setEligibleMethods] = useState({});
  const [pickupLocations, setPickupLocations] = useState([]);
  const [modalPONumber, setModalPONumber] = useState("");
  const {
    orderID,
    orderStatusType,
    quoteNumber,
    orderItems,
    currencyCode,
    orderShippingDispatchOptionsCustom,
    orderStatusHistory,
  } = quoteDetail;
  const [selectedDispatchOption, setShippingDispatchOption] = useState(
    orderShippingDispatchOptionsCustom
  );
  const [isFulfillmentLoading, setFulfillmentLoading] = useState(false);
  let selectedFulfillmentMethod = { fulfillmentMethodID: "" };
  if (
    quoteDetail.orderFulfillments?.at(0) &&
    quoteDetail.orderFulfillments?.at(0).fulfillmentMethod
  ) {
    selectedFulfillmentMethod =
      quoteDetail.orderFulfillments?.at(0).fulfillmentMethod;
  }

  useEffect(() => {
    if (orderID) {
      dispatch(
        getEligibleOrderFulfillmentMethods({
          params: { orderID: orderID },
          returnQuote: false,
          isQuote: true,
        })
      ).then((response) => {
        if (response.isSuccess()) {
          setEligibleMethods(response?.success()?.eligibleFulfillmentMethods);
        }
      });
      dispatch(getAllPickupLocations({ isQuote: true })).then((response) => {
        if (response.isSuccess()) {
          setPickupLocations(
            response?.success()?.locations.map((location) => {
              return { name: location["NAME"], value: location["VALUE"] };
            })
          );
        }
      });
    }
  }, [dispatch, orderID]);

  return (
    <div className="mt-3">
      <PONumber
        quoteDetail={quoteDetail}
        modalPONumber={modalPONumber}
        setModalPONumber={setModalPONumber}
        selectedDispatchOption={selectedDispatchOption}
        updateQuote={updateQuote}
      />
      <QuoteStatusHistory
        orderStatusHistory={orderStatusHistory}
        orderStatusType={orderStatusType}
      />

      {quoteDetail && (
        <div className="row">
          <QuoteName quoteDetail={quoteDetail} t={t} />
          <QuoteToolbar
            orderID={orderID}
            delivered={{
              orderStatusType_typeName: orderStatusType.typeName,
            }}
            orderPayments={{
              order_orderNumber: quoteNumber,
            }}
          />

          <div className="col-lg-12 col-md-12">
            {orderItems.length > 0 && (
              <>
                <div className="card mb-4">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <h4 className="p-0 mb-0"> {t("frontend.cart.item")}</h4>
                      </div>
                      <div className="col-sm-12 col-lg-7 pe-0 d-none d-lg-block">
                        <div className="row d-flex justify-content-between">
                          <div className="col-3 ">
                            <small> {t("frontend.cart.unitPrice")}</small>
                          </div>
                          {currencyCode === "EUR" && (
                            <div className="col-3 px-0">
                              <small>{t("frontend.cart.surcharge")}</small>
                            </div>
                          )}
                          <div className="col-2 px-0">
                            <small>{t("frontend.cart.quantity")}</small>
                          </div>
                          <div className="col-3 px-0">
                            <small>{t("frontend.cart.lineTotal")}</small>
                          </div>
                          <div className="col-1 px-0"></div>
                        </div>
                      </div>
                      <div
                        className="white-background position-absolute text-right px-0 bottom-0 d-lg-block d-none"
                        style={{ right: 0, width: "auto" }}
                      >
                        <button
                          className="btn btn-link link-btn btn-sm p-3 text-underline"
                          onClick={() => {
                            dispatch(
                              clearOrderData({
                                params: {
                                  orderID: orderID,
                                },
                                returnQuote: true,
                                isQuote: true,
                              })
                            ).then((response) => {
                              if (
                                response.isSuccess() &&
                                Object.keys(response.success()?.errors || {})
                                  .length
                              )
                                toast.error(
                                  getErrorMessage(response.success().errors)
                                );
                              if (response.isSuccess()) {
                                toast.success(
                                  t("frontend.account.quote.deletedMessage")
                                );
                                dispatch(clearCart());
                                updateQuote((prevState) => ({
                                  ...prevState,
                                  ...response.success().quote,
                                }));
                              }
                            });
                          }}
                        >
                          {t("frontend.account.quote.clearAllQuote")}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pe-0 py-0">
                    {orderItems &&
                      orderItems.map((orderItem) => {
                        return (
                          <CartModule.CoreComponents.CartLineItem
                            key={orderItem.orderItemID}
                            orderItem={orderItem}
                            onUpdateQty={(orderItemID, itemCount) => {
                              dispatch(
                                updateOrderItemQuantity({
                                  params: {
                                    orderItem: {
                                      orderItemID: orderItemID,
                                      quantity: itemCount,
                                    },
                                    orderID: orderID,
                                  },
                                  returnQuote: true,
                                  isQuote: true,
                                })
                              ).then((response) => {
                                if (
                                  response.isSuccess() &&
                                  Object.keys(response.success()?.errors || {})
                                    .length
                                )
                                  toast.error(
                                    getErrorMessage(response.success().errors)
                                  );
                                if (response.isSuccess()) {
                                  toast.success(
                                    t(
                                      "frontend.account.quote.detail.quantityUpdate"
                                    )
                                  );
                                  updateQuote((prevState) => ({
                                    ...prevState,
                                    ...response.success().quote,
                                  }));
                                }
                              });
                            }}
                            onRemoveItem={() => {
                              dispatch(
                                removeOrderItem({
                                  params: {
                                    orderItemID: orderItem.orderItemID,
                                    orderID: orderID,
                                  },
                                  returnQuote: true,
                                  isQuote: true,
                                })
                              ).then((response) => {
                                if (
                                  response.isSuccess() &&
                                  Object.keys(response.success()?.errors || {})
                                    .length
                                )
                                  toast.error(
                                    getErrorMessage(response.success().errors)
                                  );
                                if (response.isSuccess()) {
                                  toast.success(
                                    t(
                                      "frontend.account.quote.detail.itemRemoved"
                                    )
                                  );
                                  if (
                                    Object.keys(response.success().quote)
                                      .length === 0
                                  ) {
                                    navigate(`/my-account/quotes`);
                                  } else {
                                    updateQuote((prevState) => ({
                                      ...prevState,
                                      ...response.success().quote,
                                    }));
                                  }
                                }
                              });
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col-sm-12 col-md-12">
            <Overlay
              active={isFulfillmentLoading}
              spinner
            >
              <FulfillmentList
                orderFulfillments={quoteDetail.orderFulfillments}
                orderItems={quoteDetail.orderItems}
                canSwitchFulfillmentMethod={true}
                eligibleFulfillmentMethods={eligibleFulfillmentMethods}
                pickupLocations={pickupLocations}
                selectedFulfillmentMethod={selectedFulfillmentMethod}
                selectedDispatchOption={selectedDispatchOption}
                setShippingDispatchOption={setShippingDispatchOption}
                onChangeOrderFullfillment={(
                  fulfillmentMethodID,
                  orderItemIDList
                ) => {
                  setFulfillmentLoading(true);
                  return dispatch(
                    changeFulfillmentOnOrder({
                      params: {
                        orderID: quoteDetail.orderID,
                        fulfillmentMethodID,
                        orderItemIDList,
                      },
                      returnQuote: true,
                      isQuote: true,
                    })
                  ).then((response) => {
                    setFulfillmentLoading(false);
                    if (
                      response.isSuccess() &&
                      Object.keys(response.success()?.errors || {}).length
                    )
                      toast.error(getErrorMessage(response.success().errors));
                    if (response.isSuccess()) {
                      updateQuote((prevState) => ({
                        ...prevState,
                        ...response.success().quote,
                      }));
                    }
                  });
                }}
                onShipmentSelect={(value, orderFulfillmentID) => {
                  setFulfillmentLoading(true);
                  return dispatch(
                    addShippingAddressUsingAccountAddressToOrderFulfillment({
                      params: {
                        orderID: quoteDetail.orderID,
                        fulfillmentMethodID: orderFulfillmentID,
                        accountAddressID: value,
                      },
                      returnQuote: true,
                      isQuote: true,
                    })
                  ).then((response) => {
                    setFulfillmentLoading(false);
                    if (
                      response.isSuccess() &&
                      Object.keys(response.success()?.errors || {}).length
                    )
                      toast.error(getErrorMessage(response.success().errors));
                    if (response.isSuccess()) {
                      updateQuote((prevState) => ({
                        ...prevState,
                        ...response.success().quote,
                      }));
                    }
                  });
                }}
                onShipmentSave={(values, orderFulfillmentID) => {
                  setFulfillmentLoading(true);
                  if (values.saveAddress) {
                    return dispatch(
                      addNewAddressAndAttachAsShippingOnOrderFulfillment({
                        params: {
                          orderID: quoteDetail.orderID,
                          fulfillmentID: orderFulfillmentID,
                          accountAddressName: values?.accountAddressName,
                          city: values?.city,
                          countryCode: values?.countryCode,
                          emailAddress: values?.emailAddress,
                          locality: values?.locality,
                          name: values?.name,
                          phoneNumber: values?.phoneNumber,
                          postalCode: values?.postalCode,
                          saveAddress: values?.saveAddress,
                          stateCode: values?.stateCode,
                          street2Address: values?.street2Address,
                          streetAddress: values?.streetAddress,
                        },
                        returnQuote: true,
                        isQuote: true,
                      })
                    ).then((response) => {
                      setFulfillmentLoading(false);
                      if (
                        response.isSuccess() &&
                        Object.keys(response.success()?.errors || {}).length
                      )
                        toast.error(getErrorMessage(response.success().errors));
                      if (response.isSuccess()) {
                        updateQuote((prevState) => ({
                          ...prevState,
                          ...response.success().quote,
                        }));
                      }
                    });
                  } else {
                    return dispatch(
                      addShippingAddressToOrderFulfillment({
                        params: {
                          orderID: quoteDetail.orderID,
                          fulfillmentID: orderFulfillmentID,
                          accountAddressName: values?.accountAddressName,
                          city: values?.city,
                          countryCode: values?.countryCode,
                          emailAddress: values?.emailAddress,
                          locality: values?.locality,
                          name: values?.name,
                          phoneNumber: values?.phoneNumber,
                          postalCode: values?.postalCode,
                          saveAddress: values?.saveAddress,
                          stateCode: values?.stateCode,
                          street2Address: values?.street2Address,
                          streetAddress: values?.streetAddress,
                        },
                        returnQuote: true,
                        isQuote: true,
                      })
                    ).then((response) => {
                      setFulfillmentLoading(false);
                      if (
                        response.isSuccess() &&
                        Object.keys(response.success()?.errors || {}).length
                      )
                        toast.error(getErrorMessage(response.success().errors));
                      if (response.isSuccess()) {
                        updateQuote((prevState) => ({
                          ...prevState,
                          ...response.success().quote,
                        }));
                      }
                    });
                  }
                }}
                onSelectShippingMethod={(value, orderFulfillmentID) => {
                  setFulfillmentLoading(true);
                  dispatch(
                    addShippingMethodToOrderFulfillment({
                      params: {
                        orderID: quoteDetail.orderID,
                        fulfillmentID: orderFulfillmentID,
                        shippingMethodID: value,
                      },
                      returnQuote: true,
                      isQuote: true,
                    })
                  ).then((response) => {
                    setFulfillmentLoading(false);
                    if (
                      response.isSuccess() &&
                      Object.keys(response.success()?.errors || {}).length
                    )
                      toast.error(getErrorMessage(response.success().errors));
                    if (response.isSuccess()) {
                      updateQuote((prevState) => ({
                        ...prevState,
                        ...response.success().quote,
                      }));
                    }
                  });
                }}
                onChangeDate={(pickupDate) => {
                  setFulfillmentLoading(true);
                  dispatch(
                    setPickupDateToOrderFulfillment({
                      params: { orderID: quoteDetail.orderID, pickupDate },
                      returnQuote: true,
                      isQuote: true,
                    })
                  ).then((response) => {
                    setFulfillmentLoading(false);
                    if (
                      response.isSuccess() &&
                      Object.keys(response.success()?.errors || {}).length
                    )
                      toast.error(getErrorMessage(response.success().errors));
                    if (response.isSuccess()) {
                      updateQuote((prevState) => ({
                        ...prevState,
                        ...response.success().quote,
                      }));
                    }
                  });
                }}
                onChangeLocation={(value) => {
                  setFulfillmentLoading(true);
                  dispatch(
                    addPickupLocationToOrderFulfillment({
                      params: { orderID: quoteDetail.orderID, value },
                      returnQuote: true,
                      isQuote: true,
                    })
                  ).then((response) => {
                    setFulfillmentLoading(false);
                    if (
                      response.isSuccess() &&
                      Object.keys(response.success()?.errors || {}).length
                    )
                      toast.error(getErrorMessage(response.success().errors));
                    if (response.isSuccess()) {
                      updateQuote((prevState) => ({
                        ...prevState,
                        ...response.success().quote,
                      }));
                    }
                  });
                }}
              />
            </Overlay>
          </div>
          <QuoteSidebar
            selectedDispatchOption={selectedDispatchOption}
            {...props}
          />
        </div>
      )}
    </div>
  );
};

const QuoteDetailViewOnly = React.memo(
  ({
    quoteDetail,
    children,
    updateQuote,
    orderProperties,
    reason,
    setReason,
  }) => {
    const { t } = useTranslation();
    const {
      CommonModule: { OrderToolbar },
    } = useElementContext();
    const {
      orderStatusType,
      freightCarrier,
      specialInstructions,
      orderItems,
      currencyCode,
      orderStatusHistory,
    } = quoteDetail;
    return useMemo(() => {
      return (
        <div className=" mt-3">
          <PONumber quoteDetail={quoteDetail} />
          <QuoteName quoteDetail={quoteDetail} t={t} />
          {orderStatusType.typeCode === "qstOrderEntryRequestUpdated" && (
            <ThreadedComments comments={orderStatusHistory} />
          )}
          <div
            className="d-grid"
            style={{
              gap: "1rem",
              gridTemplateColumns:
                orderStatusHistory.length === 2 ||
                ["qstApproved","qstRejectedQuote"].includes(orderStatusType.typeCode)
                  ? "1fr"
                  : "1fr 1fr 1fr",
            }}
          >
            {orderStatusType.typeCode !== "qstOrderEntryRequestUpdated" && (
              <QuoteStatusHistory
                orderStatusHistory={orderStatusHistory}
                orderStatusType={orderStatusType}
              />
            )}
          </div>
          <AdditionalDetails
            freightCarrier={freightCarrier}
            specialInstructions={specialInstructions}
          />
          {quoteDetail && (
            <div className="row">
              <OrderToolbar
                delivered={{
                  orderStatusType_typeName:
                    quoteDetail.orderStatusType.typeName,
                }}
                orderPayments={{ order_orderNumber: quoteDetail.quoteNumber }}
              />

              <div className="col-lg-12 col-md-12">
                <div className="table-responsive font-size-md">
                  <table className="table table-striped table-borderless table-quote-items">
                    <OrderItems
                      t={t}
                      currencyCode={currencyCode}
                      orderItems={orderItems}
                      orderStatusType={orderStatusType}
                    />
                  </table>
                </div>
              </div>
              <div className="mb-2 col-sm-12">
                <QuoteFulfillmentsViewOnly quoteDetail={quoteDetail} />
                {children}
              </div>
              <QuoteSidebar
                quoteDetail={quoteDetail}
                updateQuote={updateQuote}
                orderProperties={orderProperties}
                setReason={setReason}
              />
            </div>
          )}
        </div>
      );
      // eslint-disable-next-line
    }, [
      quoteDetail,
      reason,
      t,
      children,
      updateQuote,
      orderProperties,
      setReason,
    ]);
  }
);

const Comment = ({ index, comment, length }) => {
  const [formateDate] = useFormatDate();
  return (
    <>
      {comment.reason && (
        <div className="d-flex flex-wrap">
          <p className="m-0">
            <strong>
              {comment?.orderStatusHistoryType_typeCode === "qstRejected"
                ? "CSR Feedback"
                : "Customer Comments"}{" "}
            </strong>
            : <i className="me-2">{comment.reason}</i>(
            {formateDate(comment?.createdDateTime)})
          </p>
          {index !== length - 2 && <i className="bi bi-arrow-right p-2"></i>}
        </div>
      )}
    </>
  );
};

const ThreadedComments = ({ comments }) => (
  <div className="d-flex flex-wrap">
    {comments
      .filter(
        (comment) => comment?.orderStatusHistoryType_typeCode !== "qstDraft"
      )
      .map((comment, index) => (
        <Comment key={index} index={index} comment={comment} length={comments.length} />
      ))}
  </div>
);

const QuoteDetailClarification = React.memo(
  ({
    quoteDetail,
    children,
    updateQuote,
    orderProperties,
    reason,
    setReason,
  }) => {
    const { t } = useTranslation();
    const {
      CommonModule: { OrderToolbar },
    } = useElementContext();
    const {
      freightCarrier,
      specialInstructions,
      orderItems,
      orderShippingDispatchOptionsCustom,
      orderStatusType,
      orderStatusHistory,
      currencyCode,
    } = quoteDetail;
    return useMemo(() => {
      return (
        <div className="mt-3">
          <PONumber quoteDetail={quoteDetail} />
          <QuoteName quoteDetail={quoteDetail} t={t} />
          <ThreadedComments comments={orderStatusHistory} />

          <AdditionalDetails
            freightCarrier={freightCarrier}
            specialInstructions={specialInstructions}
          />

          {quoteDetail && (
            <div className="row">
              <OrderToolbar
                delivered={{
                  orderStatusType_typeName:
                    quoteDetail.orderStatusType.typeName,
                }}
                orderPayments={{ order_orderNumber: quoteDetail.quoteNumber }}
              />

              <div className="col-lg-12 col-md-12">
                <div className="table-responsive font-size-md">
                  <table className="table table-striped table-borderless table-quote-items">
                    <OrderItems
                      t={t}
                      currencyCode={currencyCode}
                      orderItems={orderItems}
                      orderStatusType={orderStatusType}
                    />
                  </table>
                </div>
              </div>
              <div className="mb-2 col-lg-12">
                <QuoteFulfillmentsViewOnly quoteDetail={quoteDetail} />
                {children}
              </div>
              <QuoteSidebar
                quoteDetail={quoteDetail}
                updateQuote={updateQuote}
                orderProperties={orderProperties}
                setReason={setReason}
                selectedDispatchOption={orderShippingDispatchOptionsCustom}
              />
            </div>
          )}
        </div>
      );
      // eslint-disable-next-line
    }, [
      quoteDetail,
      reason,
      t,
      children,
      updateQuote,
      orderProperties,
      setReason,
    ]);
  }
);

const QuoteSidebar = React.memo(
  ({
    selectedDispatchOption = "",
    quoteDetail,
    updateQuote,
    orderProperties,
    setReason,
  }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleRemovePromoCode = useCallback(
      (promoCode) => {
        dispatch(
          removePromoCodeFromOrder({
            params: {
              orderID: quoteDetail.orderID,
              promotionCode: promoCode,
            },
            returnQuote: true,
            isQuote: true,
          })
        ).then((response) => {
          if (
            response.isSuccess() &&
            Object.keys(response.success()?.errors || {}).length
          ) {
            toast.error(getErrorMessage(response.success().errors));
          }
          if (response.isSuccess()) {
            toast.success(t("frontend.account.quote.promoCodeRemoved"));
            updateQuote((prevState) => ({
              ...prevState,
              ...response.success().quote,
            }));
          }
        });
      },
      [dispatch, quoteDetail.orderID, t, updateQuote]
    );

    return (
      <div className="col-lg-12 col-md-12">
        <div className="row">
          <div
            className={`${
              ["qstDraft", "qstRejected"].includes(
                quoteDetail.orderStatusType.typeCode
              )
                ? "col-sm-12 col-lg-6"
                : "col-sm-12 col-lg-12"
            }`}
          >
            <OrderSummary
              cart={quoteDetail}
              onRemovePromoCode={handleRemovePromoCode}
            />
            <div className="col-sm-12 col-lg-12"></div>
          </div>

          {["qstDraft", "qstRejected"].includes(
            quoteDetail.orderStatusType.typeCode
          ) && (
            <QuoteSubmitForApprovalForm
              selectedDispatchOption={selectedDispatchOption}
              quoteDetail={quoteDetail}
              updateQuote={updateQuote}
              setReason={setReason}
            />
          )}
        </div>
      </div>
    );
  }
);

const QuoteSubmitForApprovalForm = ({
  selectedDispatchOption,
  quoteDetail,
  updateQuote,
  setReason,
}) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [quoteRequestDetails, setQuoteRequestDetails] = useState(
    quoteDetail.quoteRequestDetails || ""
  );
  const [freightCarrier, setFreightCarrier] = useState(
    quoteDetail.freightCarrier || ""
  );
  const [specialInstructions, setSpecialInstructions] = useState(
    quoteDetail.specialInstructions || ""
  );

  const submitQuoteForApproval = async (orderID) => {
    try {
      setDisabled(true);
      const response = await SlatwalApiService.quotes.submitQuoteForApproval({
        orderID,
        reason: quoteRequestDetails
          ? quoteRequestDetails
          : "Order Entry Request Submitted",
        returnQuote: true,
        orderShippingDispatchOptionsCustom: selectedDispatchOption,
        freightCarrier,
        specialInstructions,
      });
      if (response.isSuccess()) {
        const { errors, quote } = response.success() || {};
        if (Object.keys(errors || {}).length) {
          toast.error(getErrorMessage(errors));
        } else {
          toast.success(t("frontend.account.quote.approval.successMessage"));
          setTimeout(() => {
            updateQuote((prevState) => ({
              ...prevState,
              ...quote,
            }));
            setReason(quoteRequestDetails);
            setDisabled(false);
          }, 2000);
        }
      }
    } catch (error) {
      console.error("Error submitting quote for approval:", error);
      toast.error("An error occurred while submitting quote for approval");
    }
  };

  const reSubmitQuoteForApproval = async (orderID) => {
    setDisabled(true);
    try {
      const response = await axios.post(
        `${getSdkURL()}api/scope/updateQuoteStatus`,
        {
          returnQuote: true,
          orderID,
          reason: quoteRequestDetails,
          quoteStatus: "qstOrderEntryRequestUpdated",
        }
      );
      if (response.data?.errors) {
        toast.error(getErrorMessage(response.data.errors));
      } else if (response.data?.error && response.data.error !== "") {
        toast.error(response.data.error);
      } else {
        toast.success("Quotes updated successfully");
        updateQuote((prevState) => ({
          ...prevState,
          ...response.data.quote,
        }));
      }
    } catch (error) {
      console.error("Error re-submitting quote for approval:", error);
      toast.error("An error occurred while re-submitting quote for approval");
    }
  };

  const disableButton = useMemo(() => {
    return (
      disabled ||
      !quoteDetail?.orderFulfillments[0]?.shippingAddress?.addressID ||
      !quoteDetail?.orderPayments?.at(0)?.purchaseOrderNumber ||
      !quoteDetail?.orderItems?.length ||
      !selectedDispatchOption
    );
    // eslint-disable-next-line
  }, [quoteDetail, disabled, selectedDispatchOption]);

  return (
    <div className="col-sm-12 col-lg-6">
      <div className="row">
        <div className="col-sm-12">
          <div className="card mb-4">
            <div className="card-header">
              <h4 className="p-0 mb-0">
                {quoteDetail.orderStatusType.typeCode === "qstDraft"
                  ? t("frontend.account.quote.submitForApproval")
                  : "Re-Submit Quote for Approval"}
              </h4>
            </div>
            <div className="card-body">
              <form name="add-quote-for-approval">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-2">
                      <label htmlFor="quoteRequestDetails">
                        {t("frontend.account.quote.quoteRequestDetailsLabel")}{" "}
                        {quoteDetail.orderStatusType.typeCode ===
                          "qstRejected" && <sup className="text-danger">*</sup>}
                      </label>
                      <textarea
                        className="form-control"
                        id="quoteRequestDetails"
                        value={quoteRequestDetails}
                        onChange={(e) => {
                          // Ensure the input does not exceed 255 characters
                          const inputValue = e.target.value;
                          if (inputValue.length <= 255) {
                            setQuoteRequestDetails(inputValue);
                          }
                        }}
                        maxLength={255}
                        required={
                          quoteDetail.orderStatusType.typeCode === "qstRejected"
                            ? true
                            : false
                        }
                        placeholder="Please enter your comments under (255 Characters)"
                      />
                    </div>
                    {quoteDetail.orderStatusType.typeCode === "qstDraft" && (
                      <>
                        <div className="form-group mb-2">
                          <label htmlFor="quoteRequestDetails">
                            Special Instructions
                          </label>
                          <textarea
                            className="form-control"
                            id="specialInstructions"
                            value={specialInstructions}
                            maxLength={255}
                            onChange={(e) => {
                              // Ensure the input does not exceed 255 characters
                              const inputValue = e.target.value;
                              if (inputValue.length <= 255) {
                                setSpecialInstructions(e.target.value);
                              }
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="freightCarrier">
                            Freight Carrier
                          </label>
                          <textarea
                            className="form-control"
                            id="freightCarrier"
                            value={freightCarrier}
                            maxLength={255}
                            onChange={(e) => {
                              // Ensure the input does not exceed 255 characters
                              const inputValue = e.target.value;
                              if (inputValue.length <= 255) {
                                setFreightCarrier(e.target.value);
                              }
                            }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {quoteDetail.orderItems.length === 0 ? (
                  <>
                    <div className="p-2 mt-3 alert alert-warning" role="alert">
                      <span>
                        {t("frontend.account.quote.emptyQuoteMessage")}
                      </span>
                    </div>
                    <Link className="btn btn-secondary d-block" to={"/shop"}>
                      <span className="d-sm-inline">
                        {t("frontend.account.quote.continueShopping")}
                      </span>
                    </Link>
                  </>
                ) : (
                  <>
                    {!disableButton && (
                      <Button
                        isLoading={disabled}
                        disabled={disableButton}
                        type="button"
                        classList="btn btn-primary btn-block mt-4 d-block m-auto"
                        onClick={() => {
                          if (
                            !quoteRequestDetails &&
                            quoteDetail.orderStatusType.typeCode ===
                              "qstRejected"
                          ) {
                            toast.error(
                              t("frontend.account.quote.requiredMessage")
                            );
                            return null;
                          } else {
                            quoteDetail.orderStatusType.typeCode === "qstDraft"
                              ? submitQuoteForApproval(quoteDetail.orderID)
                              : reSubmitQuoteForApproval(quoteDetail.orderID);
                          }
                        }}
                      >
                        <span className="d-sm-inline">
                          {quoteDetail.orderStatusType.typeCode === "qstDraft"
                            ? t("frontend.account.quote.submitForApproval")
                            : "Submit"}
                        </span>
                      </Button>
                    )}
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuoteFulfillmentsViewOnly = React.memo(({ quoteDetail }) => {
  const { orderFulfillments, orderShippingDispatchOptionsCustom } =
    quoteDetail || {};

  return (
    <>
      {orderFulfillments?.map((fulfillment) => {
        const {
          fulfillmentMethod,
          shippingAddress,
          shippingMethodOptions,
          pickupLocation,
        } = fulfillment || {};

        return (
          <div className="card mb-4" key={fulfillment.orderFulfillmentID}>
            <div className="card-header">
              <h4 className="p-0 mb-0">
                {fulfillmentMethod?.fulfillmentMethodName}
              </h4>
            </div>
            {fulfillmentMethod?.fulfillmentMethodType === SHIPPING_CODE && (
              <AddressCard
                address={shippingAddress}
                viewOnly={true}
                options={shippingMethodOptions}
              />
            )}
            {fulfillmentMethod?.fulfillmentMethodType === PICKUP_CODE && (
              <PickupLocationDetails
                pickupLocation={pickupLocation}
                displayOnly={true}
              />
            )}
            {orderShippingDispatchOptionsCustom && (
              <span className="p-3">
                <b>Shipping Dispatch Option:</b>
                <br />
                {orderShippingDispatchOptionsCustom === "shipCompleteOrder"
                  ? "Ship Complete Order"
                  : "Partial Shipment Accepted"}
              </span>
            )}
          </div>
        );
      })}
    </>
  );
});

const QuoteStatusHistory = ({ orderStatusHistory, orderStatusType }) => {
  return (
    orderStatusHistory && (
      <span className="py-1 m-0">
        <strong>
          {["qstOrderEntryRequestUpdated", "qstPendingReview"].includes(
            orderStatusType.typeCode
          )
            ? "Customer Comments: "
            : "CSR Feedback: "}
        </strong>
        {orderStatusHistory[orderStatusHistory.length - 1]?.reason}
      </span>
    )
  );
};
const QuoteName = ({ quoteDetail, t }) => {
  return (
    <span className="quoteName h6 mb-0">
      <strong>{t("frontend.cart.quoteName")}</strong> {quoteDetail.quoteName}
    </span>
  );
};

const PONumber = ({
  quoteDetail,
  modalPONumber,
  setModalPONumber,
  selectedDispatchOption,
  updateQuote,
}) => {
  const { orderStatusType, orderPayments, orderID } = quoteDetail;
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(
    orderPayments?.at(0)?.purchaseOrderNumber
  );

  const StepsLabel = useMemo(() => {
    const outstanding = [
      !quoteDetail?.orderFulfillments[0]?.shippingAddress?.addressID
        ? "delivery address"
        : "",
      !selectedDispatchOption ? "dispatch option" : "",
      !quoteDetail?.orderPayments?.at(0)?.purchaseOrderNumber
        ? " PO number"
        : "",
    ].filter(Boolean);
    if (outstanding.length)
      return `To proceed please add the following: ${outstanding.join(", ")}`;
    return "";
    // eslint-disable-next-line
  }, [quoteDetail, selectedDispatchOption, setModalPONumber]);

  const [showModal, setShowModal] = useState(false);
  const handleSubmit = () => {
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/setupTradeOrderPayment`,
      data: {
        orderID: orderID,
        purchaseOrderNumber: modalPONumber,
      },
    }).then((response) => {
      if (Object.keys(response?.data?.errors || {}).length) {
        toast.error(getErrorMessage(response?.data?.errors));
      } else if (response?.data?.error && response?.data?.error !== "") {
        toast.error(response.data.error);
      } else {
        toast.success("Order Payment has been updated.");
        setShowModal(false);
        setPurchaseOrderNumber(modalPONumber);
        updateQuote(response.data?.tradeOrder);
      }
    });
  };
  return (
    orderPayments && (
      <div className="mt-3">
        {StepsLabel && orderStatusType.typeCode === "qstDraft" && (
          <span className="my-3 alert bg-primary text-light p-2 d-flex justify-content-center align-items-center">
            {StepsLabel}
          </span>
        )}
        {orderStatusType.typeCode === "qstDraft" ? (
          <>
            <div>
              <i
                className="bi bi-pencil linkit me-2"
                onClick={() => {
                  setShowModal(true);
                  setModalPONumber(purchaseOrderNumber);
                }}
              ></i>
              <strong>PO Number: </strong>
              <span>{purchaseOrderNumber}</span>
            </div>
            {showModal && (
              <Modal
                show={showModal}
                setShow={setShowModal}
                title="Order Payment"
                size="xMedium"
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  className="text-center"
                >
                  <div className="col-12 d-flex mb-2">
                    <div className="col-4">
                      <label className="form-label">PO Number*</label>
                    </div>
                    <div className="col-8">
                      <input
                        name="purchaseOrderNumber"
                        value={modalPONumber}
                        onChange={(e) => {
                          setModalPONumber(e.target.value);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <button className="btn btn-primary my-2" type="submit">
                    Save
                  </button>
                </form>
              </Modal>
            )}
          </>
        ) : (
          <>
            <strong>PO Number: </strong>
            <span>{purchaseOrderNumber}</span>
          </>
        )}
      </div>
    )
  );
};

const AdditionalDetails = ({ freightCarrier, specialInstructions }) => {
  return (
    <>
      {freightCarrier && (
        <div className="m-0">
          <strong>Freight Carrier: &nbsp;</strong>
          {freightCarrier}
        </div>
      )}
      {specialInstructions && (
        <div className="mb-1 m-0">
          <strong>Special Instructions: &nbsp;</strong>
          {specialInstructions}
        </div>
      )}
    </>
  );
};

const OrderItems = ({ currencyCode, t, orderItems, orderStatusType }) => {
  return (
    <>
      <thead className="thead-light">
        <tr>
          <th>{t("frontend.cart.item")}</th>
          <th>{t("frontend.cart.unitPrice")}</th>
          {currencyCode === 'EUR' && <th>{t("frontend.cart.surcharge")}</th>}
          <th>{t("frontend.cart.quantity")}</th>
          <th>{t("frontend.cart.lineTotal")}</th>
        </tr>
      </thead>
      <tbody>
        {orderItems
          ?.map((orderItem) => {
            orderItem["sku_product_productName"] =
              orderItem.sku?.product?.productName;
            orderItem["sku_skuID"] = orderItem.sku?.skuID;
            orderItem["sku_product_urlTitle"] =
              orderItem.sku?.product?.urlTitle;
            orderItem["images"] = orderItem.sku?.images;
            orderItem["BrandName"] = orderItem.sku?.product?.brand?.brandName;
            orderItem["calculatedExtendedPriceAfterDiscount"] =
              orderItem.extendedPriceAfterDiscount;
            orderItem["sku_product_productID"] =
              orderItem.sku?.product?.productID;
            return orderItem;
          })
          ?.map((orderItem) => {
            return (
              <OrderItemV2
                key={orderItem.orderItemID}
                {...orderItem}
                showActions={false}
                orderStatusType={orderStatusType}
              />
            );
          })}
      </tbody>
    </>
  );
};

const AddressCard = ({ address, viewOnly = false, onClick, children }) => {
  const { t } = useTranslation();

  const AddressInfo = ({ address }) => (
    <>
      {address.name}
      <br />
      {address.streetAddress} <br />
      {`${address.city}, ${address.stateCode} ${address.postalCode}`} <br />
    </>
  );

  return (
    <section className="address-card p-3">
      <h6 className="h6 fw-bold shipping-address-title">
        {t("frontend.checkout.shipping_address")}
      </h6>
      <AddressInfo address={address} />
      {children}
      {!viewOnly && (
        <>
          <button
            className="btn btn-link text-danger"
            type="button"
            disabled={false}
            onClick={(event) => {
              event.preventDefault();
              onClick();
            }}
          >
            <i className="bi bi-times-circle"></i>
            <span className="small ">
              {" "}
              {t(`frontend.core.changeSelection`)}
            </span>
          </button>
        </>
      )}
    </section>
  );
};

export { QuoteDetailV2 };