import { useState, useRef, Dispatch, SetStateAction, useMemo } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { CancelTokenSource } from "axios";
import { toast } from "react-toastify";
import { getErrorMessage } from "@ultracommerce/react-storefront/global/src/utils";
import {
  axios,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { getSdkURL } from "@ultracommerce/react-storefront/global";
import { useCustomFormatCurrency } from "../../hooks/useFormatCurrency";
import { useSelector } from "react-redux";
import {
  calMinDeliveryDate,
  calStepAndMinQuantity,
  modelNumberToSkuConfiguration,
} from "../../utils";
import { useStepQuantityInput } from "../../hooks/useStepQuantityInput";
import { useTranslation } from "react-i18next";
import Typeahead from "react-bootstrap-typeahead/types/core/Typeahead";
import { bulkCalculatePrice } from "../../actions/cartActions";
import { SearchSku } from "@ultracommerce/react-storefront/global/src/interface/SearchProduct";

interface CustomCode {
  customCode: string;
  modelNumber: string;
  price: number;
}

interface IOrderItem {
  sku: SearchSku;
  skuConfiguration: { [key: string]: string };
  quantity: string;
  customCode: CustomCode;
  calculatedData: any;
  requestedDeliveryDate: string;
  customerMaterialNumber: string;
}

const OrderItem = ({
  index,
  orderItem,
  setOrderItems,
}: {
  index: number;
  orderItem: IOrderItem;
  setOrderItems: Dispatch<SetStateAction<IOrderItem[]>>;
}) => {
  const {
    CommonModule: { SelectedSkuConfiguration },
  } = useElementContext();
  const { t } = useTranslation();
  const [formatCurrency] = useCustomFormatCurrency({});
  const { stepQty, minQty } = calStepAndMinQuantity(
    (orderItem.sku as any).modifiers,
    orderItem.skuConfiguration
  );
  const inputProps = useStepQuantityInput({ stepQty, minQty });
  const { minDeliveryDate, leadTime } = useMemo(() => {
    return calMinDeliveryDate(
      orderItem.calculatedData?.modelPriceInfo?.skuConfigurationPrice
        ?.effectiveLeadTime
    );
  }, [
    orderItem.calculatedData?.modelPriceInfo?.skuConfigurationPrice
      ?.effectiveLeadTime,
  ]);
  return (
    <tr key={index}>
      <td className="ps-1 pe-3 pb-2">
        <span className="font-weight-bold d-block">{orderItem.sku.product_productName}</span>
        <span className="small d-block my-2">
          {t("frontend.quote.modelNumber")}:{" "}
          {orderItem.calculatedData?.modelNumber}
        </span>
        <span className="small d-block my-2">
          {t("frontend.quote.leadTime", { leadTime })}
        </span>
        <span className="small d-block my-2">
          {t("frontend.quote.customCode")} :{" "}
          {orderItem.customCode.customCode}
        </span>
        <SelectedSkuConfiguration
          modifiers={(orderItem.sku as any).modifiers}
          skuConfiguration={orderItem.skuConfiguration}
        />
      </td>
      <td className="py-3">
        <input
          min={minDeliveryDate}
          required
          type="date"
          className="form-control"
          style={{ borderRadius: "30px" }}
          name="requestedDeliveryDate"
          value={orderItem.requestedDeliveryDate}
          onChange={(e) => {
            setOrderItems((prevState) => {
              const newState = [...prevState];
              newState[index] = {
                ...prevState[index],
                requestedDeliveryDate: e.target.value,
              };
              return newState;
            });
          }}
        />
      </td>
      <td className="py-3">
        <input
          type="text"
          className="form-control"
          style={{ borderRadius: "30px" }}
          name="customerMaterialNumber"
          value={orderItem.customerMaterialNumber}
          onChange={(e) => {
            setOrderItems((prevState) => {
              const newState = [...prevState];
              newState[index] = {
                ...prevState[index],
                customerMaterialNumber: e.target.value,
              };
              return newState;
            });
          }}
        />
      </td>
      <td style={{ paddingTop: '1.5rem'}}>
        {formatCurrency(
          orderItem.customCode.price,
          orderItem.calculatedData.modelPriceInfo.currencyCode
        )}
      </td>
      <td className="py-3">
        <input
          {...inputProps}
          required
          type="number"
          className="form-control"
          style={{ borderRadius: "30px", width: "5em" }}
          name="qty"
          value={orderItem.quantity}
          onChange={(e) => {
            setOrderItems((prevState) => {
              const newState = [...prevState];
              newState[index] = {
                ...prevState[index],
                quantity: e.target.value,
              };
              return newState;
            });
          }}
        />
      </td>
      <td style={{ paddingTop: '1.5rem', fontWeight: 'bold'}}>
        {formatCurrency(
          parseInt(orderItem.quantity, 10) * orderItem.customCode.price,
          orderItem.calculatedData.modelPriceInfo.currencyCode
        )}
      </td>
      <td style={{ paddingTop: '1.5rem'}}>
        <i
          className="bi bi-trash3 m-2"
          role="button"
          onClick={() => {
            setOrderItems((prevState) =>
              prevState.filter((_, i) => i !== index)
            );
          }}
        ></i>
      </td>
    </tr>
  );
};

const CustomCodeTab = ({
  orderID,
  getOrderInfo,
  isAddingProduct,
  setAddingProduct,
}: {
  orderID: string;
  getOrderInfo: (options?: {
    isFetching?: boolean;
    makeRequest?: boolean;
    isLoaded?: boolean;
  }) => void;
  isAddingProduct: boolean;
  setAddingProduct: (isAdding: boolean) => void;
}) => {
  const { t } = useTranslation();
  const typeaheadRef = useRef<Typeahead>(null);
  const [orderItems, setOrderItems] = useState<IOrderItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customCodes, setCustomCodes] = useState<CustomCode[]>([]);
  const { addressZoneName } = useSelector((state: any) => state.userReducer);

  const sourceRef = useRef<CancelTokenSource>();

  const predictiveSearch = async (searchstring: string) => {
    if (sourceRef.current) sourceRef.current.cancel();
    sourceRef.current = axios.CancelToken.source();
    setIsLoading(true);
    try {
      const { data } = await axios({
        withCredentials: true,
        url: `${getSdkURL()}api/scope/searchCustomCodes?keyword=${searchstring}`,
        cancelToken: sourceRef.current.token,
      });
      setCustomCodes(data.customCodes);
      setIsLoading(false);
    } catch {}
  };

  const addOrderItems = () => {
    if (isAddingProduct) return;
    setAddingProduct(true);
    getOrderInfo({ makeRequest: false });

    const skus = orderItems.map((item) => ({
      skuID: item.sku.skuID,
      quantity: parseInt(item.quantity, 10),
      customerMaterialNumber: item.customerMaterialNumber || "",
      plannedDeliveryDate: item.requestedDeliveryDate,
      requestedDeliveryDate: item.requestedDeliveryDate,
      currencyCode: item.calculatedData.modelPriceInfo.currencyCode,
      modelNumber: item.customCode.modelNumber,
      customCode: item.customCode.customCode,
    }));
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/addOrderItemsV2`,
      data: {
        orderID,
        skus,
        currencyCode: skus?.at(0)?.currencyCode,
      },
    })
      .then((response) => {
        if (
          response?.status === 200 &&
          response?.data?.failureActions.length === 0
        ) {
          setOrderItems([]);
          toast.success("Items added successfully");
          getOrderInfo();
        } else {
          toast.error(getErrorMessage(response?.data?.failureActions));
          getOrderInfo({
            isFetching: false,
            makeRequest: false,
            isLoaded: true,
          });
        }
      })
      .finally(() => {
        setAddingProduct(false);
      });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        addOrderItems();
      }}
    >
      <div className="d-flex justify-content-between p-2 my-2">
        <div className="col-md-8 col-lg-4">
          <AsyncTypeahead
            className="border m-0 quickAdd-typeahead"
            id="customCodeTypeahead"
            isLoading={isLoading}
            labelKey={(option: any) => (option as CustomCode)?.customCode}
            onSearch={predictiveSearch}
            options={customCodes}
            placeholder="Search Custom Code"
            ref={typeaheadRef}
            onChange={async (selected) => {
              try {
                if (selected.length > 0) {
                  const customCode = selected[0] as CustomCode;
                  typeaheadRef.current?.clear();
                  const skuCode = customCode.modelNumber.slice(0, 3);
                  const sku = await fetch(
                    `${process.env.REACT_APP_DELTA_STORE_URL}/public/ultracommerce/product/transform/skus/bySkuCode/${skuCode}`
                  ).then(async (data) => data.json());
                  sku.modifiers =
                    sku[
                      addressZoneName === "US" ? "modifiersUS" : "modifiersEU"
                    ];
                  const skuConfiguration = modelNumberToSkuConfiguration(
                    customCode.modelNumber,
                    sku.modifiers
                  );
                  if (
                    Object.keys(skuConfiguration).length !==
                    sku.modifiers.length
                  )
                    throw new Error("Invalid Model Number");
                  const { minQty } = calStepAndMinQuantity(
                    sku.modifiers,
                    skuConfiguration
                  );
                  const quantity = minQty;
                  const {
                    data: { prices },
                  } = await bulkCalculatePrice([
                    {
                      skuID: sku.skuID,
                      quantity,
                      modelNumber: customCode.modelNumber,
                    },
                  ]);
                  const calculatedData = prices[0];
                  const { minDeliveryDate } = calMinDeliveryDate(
                    (calculatedData as any)?.modelPriceInfo
                      ?.skuConfigurationPrice?.effectiveLeadTime
                  );
                  setOrderItems((prevState) => {
                    return [
                      ...prevState,
                      {
                        sku,
                        skuConfiguration,
                        quantity,
                        calculatedData,
                        customCode,
                        customerMaterialNumber: "",
                        requestedDeliveryDate: minDeliveryDate,
                      },
                    ];
                  });
                }
              } catch {
                toast.error("Invalid Custom Code");
              }
            }}
          />
        </div>
        <button
          className="btn btn-primary"
          type="submit"
          disabled={!orderItems.length}
        >
          {isAddingProduct ? (
            <span
              className="spinner-border spinner-border-sm p-2"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Add Items To List"
          )}
        </button>
      </div>
      <div className="col-12 table-responsive">
        <table className="table w-100">
          {orderItems.length > 0 && (
            <thead>
              <tr>
                <th style={{ width: "35%", minWidth: "35%" }}>Item</th>
                <th>{t("frontend.quote.customerRequestDate")}</th>
                <th>{t("frontend.quote.customMaterialNumber")}</th>
                <th>{t("frontend.cart.unitPrice")}</th>
                <th>{t("frontend.core.quantity")}</th>
                <th>{t("frontend.cart.lineTotal")}</th>
              </tr>
            </thead>
          )}
          <tbody>
            {orderItems.length > 0 ? (
              orderItems.map((orderItem, index) => {
                return (
                  <OrderItem
                    key={`${orderItem.customCode.customCode}_${orderItem.calculatedData.modelNumber}`}
                    index={index}
                    orderItem={orderItem}
                    setOrderItems={setOrderItems}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  Please search by custom code to add items
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default CustomCodeTab;
