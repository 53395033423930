import { useTranslation } from "react-i18next";
import { Sku } from "@ultracommerce/react-storefront/global/src/interface/Product";
import { Quote } from "@ultracommerce/react-storefront/global/src/interface/Quote";
import { List } from "@ultracommerce/react-storefront/global/src/interface/List";
import { getPrimaryAddress, useElementContext } from "@ultracommerce/react-storefront/global";
import { useSelector } from 'react-redux'
import { bulkCalculatePrice } from '../../../actions/cartActions'
import { useEffect, RefObject, useMemo } from 'react'
import { useCalculateData } from "../../../contexts/CalculatedDataProvider";
import { toast } from "react-toastify";
import { useCustomFormatCurrency } from "../../../hooks/useFormatCurrency";
import { calMinDeliveryDate } from "../../../utils";

export interface ProductMultiCartButtonsProps {
  formRef: RefObject<HTMLFormElement>
  sku: Sku;
  quotes: Quote[];
  wishlists: List;
  isLoading: boolean;
  setQuoteModal: (isOpen: boolean) => void;
  setListModal: (isOpen: boolean) => void;
  addToCartOrQuote: (quote?: Quote) => void;
  showExistingQuoteAndLists: boolean;
}

const ProductMultiCartButtons = ({
  formRef,
  sku,
  quotes,
  wishlists,
  showExistingQuoteAndLists,
  isLoading,
  setQuoteModal,
  addToCartOrQuote,
  setListModal,
  quantity,
  modelNumber,
  requestedDeliveryDate,
  setRequestedDeliveryDate,
  materialNumber,
  setMaterialNumber,
  isCalculatePriceButtonLoading,
  setIsCalculatePriceButtonLoading
}: any) => {
  const { t } = useTranslation();
  const { calculatedData, setCalculatedData } = useCalculateData();
  const {
    CommonModule: { Button },
  } = useElementContext();
  const [formatCurrency] = useCustomFormatCurrency({});
  
  const primaryAddress = useSelector(getPrimaryAddress);
  const { leadTime, minDeliveryDate } = useMemo(
    () => calMinDeliveryDate(calculatedData?.modelPriceInfo?.skuConfigurationPrice?.effectiveLeadTime),
    [calculatedData?.modelPriceInfo?.skuConfigurationPrice?.effectiveLeadTime]
  );
  useEffect(() => {
      setRequestedDeliveryDate(minDeliveryDate);
  }, [minDeliveryDate, setRequestedDeliveryDate]);
  const handleChange = (e: any) => {
    setRequestedDeliveryDate(e.target.value);
  };

  useEffect(() => {
    setCalculatedData('')
  }, [sku, modelNumber, quantity, setCalculatedData])

  return (
    <>
      {calculatedData &&
        <>
          <h4>Total incl (Surcharges and Taxes): {formatCurrency(calculatedData?.modelPriceInfo?.totalModelPriceAfterDiscountAfterSurchargeAfterTax, calculatedData?.modelPriceInfo?.currencyCode)}</h4>
          <h5 className="mt-2 fw-normal">{t("frontend.quote.leadTime", { leadTime })}</h5> <p>Note: If you have urgent demand, contact our CSR for air freight option.</p>
          <div className="col-sm-6 my-2 mb-4">
            <div className="form-group">
              <label htmlFor="requestedDeliveryDate">Preferred Delivery Date:</label>
              <input
                className="form-control"
                value={requestedDeliveryDate}
                type="date"
                onChange={handleChange}
                id="requestedDeliveryDate"
                min={minDeliveryDate}
              />
            </div>
          </div>
          <div className="col-sm-12 my-2 mb-4">
            <label htmlFor="materialNumber">Please input the material number of your company, if any:</label>
            <input
              className="form-control"
              value={materialNumber}
              type="text"
              onChange={(e) => setMaterialNumber(e.target.value)}
              id="materialNumber"
            />
          </div>
        </>
      }
      <div className="accordion accordion-cart" id="accordioncartExample" style={{ maxWidth: "100%" }}>
        <div className="accordion-item quote-input-box d-flex">
          {/* Add to cart button */}
          {(!calculatedData) && <Button
            type="submit"
            classList="btn btn-primary flex-grow-1 text-center"
            isLoading={isCalculatePriceButtonLoading}
            disabled={!sku.settings.skuAllowAddToCartFlag || isCalculatePriceButtonLoading}
            onClick={(e) => {
              e.preventDefault();
              setIsCalculatePriceButtonLoading(true);
              if (!primaryAddress?.address?.countrycode) {
                toast.error("Please add an address to your profile to calculate price.");
                return;
              }
              if(!formRef.current || formRef.current.reportValidity()) {
                //@ts-ignore
                bulkCalculatePrice([{skuID: sku?.skuID, quantity, modelNumber }]).then((response) => {
                  if (response.status === 200) {
                    setIsCalculatePriceButtonLoading(false)
                    setCalculatedData(response.data.prices[0])
                  }
                })
              }
            }}
          >
            Calculate Price
          </Button>
          }
          {calculatedData && <Button
            classList="btn btn-primary flex-grow-1 text-center"
            isLoading={isLoading}
            disabled={!sku.settings.skuAllowAddToCartFlag}
            onClick={(e) => {
              e.preventDefault();
              if(!formRef.current || formRef.current.reportValidity()) {
                setQuoteModal(true)
              }
            }}
          >
            {t("frontend.quote.addToQuote")}
          </Button>}
        </div>
      </div>
    </>
  );
};

export { ProductMultiCartButtons };
