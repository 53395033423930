import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProductFormModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductForm/ProductForm.componentModel";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { ProductMultiCartButtons } from "../../Controls/ProductMultiCartButtons";
import { useRef, useState } from "react";
import { AddProductToQuoteModal } from "../AddProductToQuoteModal/AddProductToQuoteModal.component";

interface _ProductFormModel extends ProductFormModel {
  stepQty?: number;
  minQty?: number;
  modelNumber?: string;
}

const ProductFormView = ({
  viewData: {
    authenticationRequiredForAddToCart,
    sku,
    quotes,
    wishlists,
    showInputLabel,
    quantityInputMode,
    showInventory,
    showExistingQuoteAndLists,
    isLoading,
    listModal,
    quoteModal,
    itemCount,
    skuConfiguration,
    stepQty = 1,
    minQty = 1,
    modelNumber,
  },
  setListModal,
  setQuoteModal,
  setItemCount,
  addToCartOrQuote,
}: {
  viewData: _ProductFormModel;
  setListModal: (isOpen: boolean) => void;
  setQuoteModal: (isOpen: boolean) => void;
  setItemCount: (n: number) => void;
  addToCartOrQuote: (quote?: any) => void;
}) => {
  //changes - getting config as a param
  const { t } = useTranslation();
  const { ProductModule } = useElementContext();
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState("");
  const [materialNumber, setMaterialNumber] = useState("");
  const [isCalculatePriceButtonLoading, setIsCalculatePriceButtonLoading] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  if (authenticationRequiredForAddToCart)
    return (
      <div className="alert alert-warning fs-6" role="alert">
        {t("frontend.product.loginToAdd")}
        <br />
        <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
      </div>
    );

  return (
    <>
      <form
        ref={formRef}
        className="productDetail-form align-items-end w-100"
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <div className="col-12 form-group align-items-center">
          {(sku.settings.skuAllowAddToCartFlag ||
            sku.settings.skuAllowQuoteFlag) && (
            <>
              <ProductModule.CoreComponents.ProductOutOfStock
                isFetching={isLoading}
                sku={sku}
              />
              <ProductModule.CoreControl.ProductQuantityInput
                sku={sku}
                quantity={itemCount}
                setQuantity={setItemCount}
                showLabel={showInputLabel}
                quantityInputType={quantityInputMode}
                showInventory={showInventory}
                // @ts-ignore
                stepQty={stepQty}
                minQty={minQty}
                isCalculatePriceButtonLoading={isCalculatePriceButtonLoading}
                setIsCalculatePriceButtonLoading={setIsCalculatePriceButtonLoading}
              />
            </>
          )}
        </div>
        <div className="col-12">
          <ProductMultiCartButtons
            formRef={formRef}
            sku={sku}
            quotes={quotes}
            wishlists={wishlists}
            isLoading={isLoading}
            setQuoteModal={setQuoteModal}
            addToCartOrQuote={addToCartOrQuote}
            setListModal={setListModal}
            showExistingQuoteAndLists={showExistingQuoteAndLists}
            modelNumber={modelNumber}
            quantity={itemCount}
            requestedDeliveryDate={requestedDeliveryDate}
            setRequestedDeliveryDate={setRequestedDeliveryDate}
            materialNumber={materialNumber}
            setMaterialNumber={setMaterialNumber}
            isCalculatePriceButtonLoading={isCalculatePriceButtonLoading}
            setIsCalculatePriceButtonLoading={setIsCalculatePriceButtonLoading}
          />
        </div>
      </form>
      {listModal && (
        <ProductModule.CoreComponents.AddProductToListModal
          sku={sku}
          setListModal={setListModal}
        />
      )}
      {quoteModal && (
        <AddProductToQuoteModal
          skuID={sku?.skuID || ""}
          setQuoteModal={setQuoteModal}
          quantity={itemCount}
          skuConfiguration={skuConfiguration}
          requestedDeliveryDate={requestedDeliveryDate}
          materialNumber={materialNumber}
          modelNumber={modelNumber}
        />
      )}
    </>
  );
};

export { ProductFormView };
