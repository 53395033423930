import { useSelector } from "react-redux";
import { checkInvetory } from "@ultracommerce/react-storefront/global";
import { ProductQuantityInputProps as _ProductQuantityInputProps } from "@ultracommerce/react-storefront/global/src/modules/Product/Controls/ProductQuantityInput";
import { useStepQuantityInput } from "../../../hooks/useStepQuantityInput";

export interface ProductQuantityInputProps extends _ProductQuantityInputProps {
  stepQty: string;
  minQty: string;
  isCalculatePriceButtonLoading: boolean;
  setIsCalculatePriceButtonLoading: (value: boolean) => void
}

// render quantity input on the basis of config
// renders input on text, dropdown on dropdown
function ProductQuantityInput({
  setQuantity,
  quantity,
  sku,
  showLabel = true,
  quantityInputType = "text",
  showInventory = false,
  dropdownLimitCount = 8,
  stepQty,
  minQty,
  isCalculatePriceButtonLoading,
  setIsCalculatePriceButtonLoading
}: ProductQuantityInputProps) {
  //changes - getting config as a param
  //changes -  extracting the required data from the config
  const stock = sku.calculatedQATS;
  const checkInventoryFlag = useSelector(checkInvetory);

  const validateQuantity = (value: string) => {
    const newQuantity = parseInt(value, 10);
    if (checkInventoryFlag) {
      if (
        newQuantity <= sku.skuOrderMinimumQuantity ||
        newQuantity > sku.skuOrderMaximumQuantity
      )
        return;
    }
    setQuantity(newQuantity);
  };

  const calculateDropdownLimit = () => {
    if (checkInventoryFlag)
      return sku.calculatedQATS > 20 ? 20 : sku.calculatedQATS;
    return dropdownLimitCount;
  };

  const inputProps = useStepQuantityInput({ stepQty, minQty });

  return (
    //changes - style changes for better look
    <div className="d-flex align-items-end gap-4 mb-3">
      <div className="d-flex flex-column">
        {quantityInputType === "text" && (
          <>
            {showLabel && <label>Quantity</label>}
            <input
              {...inputProps}
              type="number"
              onChange={(event) => {
                setIsCalculatePriceButtonLoading(false);
                validateQuantity(event.target.value);
              }}
              value={quantity}
              className="form-control rounded-pill"
              style={{ width: "5rem" }}
            />
          </>
        )}
        {sku && quantityInputType === "dropdown" && (
          <>
            {showLabel && <label>Quantity</label>}
            <select
              value={quantity}
              onChange={(event) => {
                validateQuantity(event.target.value);
              }}
              className="custom-select "
              style={{ width: "5rem" }}
            >
              {Array.from({ length: calculateDropdownLimit() }, (v, index) => (
                <option key={index + 1} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </select>
          </>
        )}
      </div>
      {showInventory && stock > 0 && (
        //changes - style changes for better look
        <div className="btn border">
          {stock}
          <span className="text-accent"> in Stock</span>
        </div>
      )}
    </div>
  );
}

export { ProductQuantityInput };
