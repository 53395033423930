import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProductRoute } from "@ultracommerce/react-storefront/global";

import { useElementContext } from "@ultracommerce/react-storefront/global";
import { useCustomFormatCurrency } from "../../../../hooks/useFormatCurrency";
import { ProductPrice } from "../../../Product/Components/ProductPrice/ProductPrice.component";
import { useStepQuantityInput } from "../../../../hooks/useStepQuantityInput";
import { useUpdatedRequestDeliveryDate } from "../../../../hooks/useUpdateRequestDeliveryDate";
import { useOrderItemPrice } from "../../../../hooks/useOrderItemPrice";

const CartLineItemView = ({
  componentData: { childBundleItems, orderItem, isDisabled, isRemovingItem, itemCount },
  setItemCount,
  onUpdateQty,
  onRemoveItem,
}: {
  componentData: any;
  onRemoveItem: () => void;
  onUpdateQty: () => void;
  setItemCount: (count: number) => void;
}) => {
  const {
    CommonModule: { SimpleImage },
    CartModule
  } = useElementContext();
  const [formatCurrency] = useCustomFormatCurrency({});
  const { t } = useTranslation();
  const productRouting = useSelector(getProductRoute);
  let { id } = useParams();

  const { render: { renderInput, renderActionButton }, actions: { saveRequestedDeliveryDate } } = useUpdatedRequestDeliveryDate({ orderItem, orderID: id! })
  const inputProps = useStepQuantityInput({ stepQty: orderItem.stepQty, minQty: orderItem.minQty });
  const mobileInput = useStepQuantityInput({ stepQty: orderItem.stepQty, minQty: orderItem.minQty });
  const { unitPrice, showSurcharge, surcharge, totalPrice } = useOrderItemPrice(orderItem);

  return (
    <div className="row py-3 cart-item-box">
      {orderItem.errorMsg && (
        <div>
          <p className="text-danger">{orderItem.errorMsg}</p>
        </div>
      )}
      <div className="row detail">
        <div className="col-lg-5 col-sm-12">
          <div className="title">
            <h4>
              <Link
                to={{
                  pathname: `/${productRouting}/${orderItem.sku.product.urlTitle}`,
                  // @ts-ignore
                  state: { ...orderItem.sku.product },
                }}
                className="text-decoration-none text-dark"
              >
                {orderItem.sku.product.productName}
              </Link>
            </h4>
          </div>
          <div className="font-size-sm item-sku model-number">
            <span className="mr-2">
              {t("frontend.quote.modelNumber")}: {orderItem?.modelNumber}
            </span>
          </div>
          <div className="font-size-sm item-sku">
            {orderItem?.effectiveLeadTime &&
              <span className="mr-2">
                {t("frontend.quote.leadTime", { leadTime: parseInt(orderItem?.effectiveLeadTime, 10) })}
              </span>
            }
          </div>
          <div className="font-size-sm item-sku">
            {orderItem?.requestedDeliveryDate && (
              <form className="my-2 form-group d-flex flex-column" onSubmit={saveRequestedDeliveryDate}>
                <span className="d-flex align-items-center justify-content-between">
                  {t("frontend.quote.customerRequestDate")}:
                  {renderInput()}
                </span>
                <div className="d-flex justify-content-end pt-1">{renderActionButton()}</div>

              </form>
            )}
          </div>
          <div className="font-size-sm item-sku">
            {orderItem?.customerMaterialNumber &&
              <span className="mr-2 d-block">
                {t("frontend.quote.customMaterialNumber")}: {orderItem?.customerMaterialNumber}
              </span>
            }
            {orderItem?.improCustomCode &&
              <span className="mr-2 d-block">
                {t("frontend.quote.customCode")}: {orderItem?.improCustomCode}
              </span>
            }
          </div>
          <CartModule.CoreControl.OrderItemSkuConfigurations skuConfigurations={orderItem?.skuConfigurations} />
        </div>
        <ul className="list-group list-group-flush d-lg-none">
          <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0"> {t("frontend.cart.unitPrice")}</h6>
            <span className="float-end">
              <div className="item-price">
                <ProductPrice
                  type="cart"
                  salePrice={unitPrice}
                  listPrice={unitPrice}
                  currencyCode={orderItem.currencyCode}
                />
              </div>
            </span>
          </li>
          {showSurcharge && <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0">{t("frontend.cart.surcharge")}</h6>
            <span className="float-end">
              <div className="surcharge">
                {/* TO DO add surcharge */}
                <h5 className="total-price">{surcharge ? formatCurrency(surcharge, orderItem?.currencyCode) : formatCurrency(0, orderItem?.currencyCode)}</h5>
              </div>
            </span>
          </li>}
          <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0"> {t("frontend.cart.quantity")}</h6>
            <span className="float-end">
              {!isDisabled ? (
                <form className="number-range d-flex flex-column" onSubmit={(e) => {
                  e.preventDefault();
                  onUpdateQty();
                }}>
                  <input
                    {...mobileInput}
                    type="number"
                    className=""
                    value={itemCount}
                    disabled={isRemovingItem && !!orderItem.sku.skuID}
                    onChange={(e) => {
                      setItemCount(parseInt(e.target.value, 10));
                    }}
                  />
                  {itemCount !== orderItem.quantity && <button className="btn text-muted btn-link p-1 mt-1">
                    {t("frontend.account.cart.item.updateQuantity")}
                  </button>}
                </form>
              ) : (
                <div className="col-2 quantity">
                  {orderItem.quantity}
                </div>
              )}
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0"> {t("frontend.cart.lineTotal")}</h6>
            <span className="float-end">
              <div className="line-total">
                <span className="fw-bold">{totalPrice ? formatCurrency(totalPrice, orderItem?.currencyCode) : formatCurrency(0, orderItem?.currencyCode)}</span>
              </div>
            </span>
          </li>
          <li className="list-group-item d-flex justify-content-between ">
            <h6 className="my-0">{t("frontend.cart.removeOrderItem")}</h6>
            <span className="float-end">
              <div className="">
                <div className="item-delete-btn">
                  <span className={`bi bi-trash ${isRemovingItem ? "pe-none" : "clickable"}`} onClick={onRemoveItem}></span>
                </div>
              </div>
            </span>
          </li>
        </ul>
        <div className="col-7 d-flex bottom-detail-box d-none d-lg-block">
          <div className="row d-flex justify-content-between">
            <div className="item-price col-3">
              <ProductPrice
                type="cart"
                salePrice={unitPrice}
                listPrice={unitPrice}
                currencyCode={orderItem.currencyCode}
              />
            </div>
            {showSurcharge && <div className="col-3">
              {/* TO DO fix surcharge */}
              <span className="total-surcharge">{surcharge ? formatCurrency(surcharge, orderItem?.currencyCode) : formatCurrency(0, orderItem?.currencyCode)}</span>
            </div>}
            {!isDisabled ? (
              <form
                className="number-range col-2 px-0"
                onSubmit={(e) => {
                  e.preventDefault();
                  onUpdateQty();
                }}
              >
                <input
                  {...inputProps}
                  type="number"
                  className=""
                  value={itemCount}
                  disabled={isRemovingItem && !!orderItem.sku.skuID}
                  onChange={(e) => {
                    setItemCount(parseInt(e.target.value, 10));
                  }}
                />
                {itemCount !== orderItem.quantity && <button className="btn text-muted btn-link p-1 text-end">
                  {t("frontend.account.cart.item.updateQuantity")}
                </button>}
              </form>
            ) : (
              <div className="col-2">
                <small>{t("frontend.cart.quantity")}</small> {orderItem.quantity}
              </div>
            )}
            <div className="col-3">
              <span className="fw-bold">{totalPrice ? formatCurrency(totalPrice, orderItem?.currencyCode) : formatCurrency(0, orderItem?.currencyCode)}</span>
            </div>
            <div className="item-delete-btn col-1 px-0 py-1">
              <span className={`bi bi-trash ${isRemovingItem ? "pe-none" : "clickable"}`} onClick={onRemoveItem}></span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {childBundleItems?.map((childBundleItem: any, key: any) => {
          return (
            <div className="col-3 d-flex" key={childBundleItem.orderItemID}>
              {key !== 0 ? (
                <i className="bi bi-plus-circle col-2 align-self-center"></i>
              ) : (
                <div className="col-2"></div>
              )}
              <Link className="col-10" to={`/${productRouting}/${childBundleItem.sku.product.urlTitle}`}>
                <SimpleImage
                  className="img-fluid  m-auto image_container productImage border border-light"
                  src={childBundleItem.sku.images?.at(0)}
                  alt={childBundleItem?.sku?.product?.productName}
                  type="product"
                />
                <span className="text-dark">
                  {` ${formatCurrency(childBundleItem.price)} x ${childBundleItem.quantity}`}
                </span>
                <p>{childBundleItem?.sku?.product?.productName}</p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export { CartLineItemView };
