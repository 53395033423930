import { useState, useRef, useMemo, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { getErrorMessage } from "@ultracommerce/react-storefront/global/src/utils";
import { axios } from "@ultracommerce/react-storefront/global/src/services/AxiosService";
import { getSdkURL } from "@ultracommerce/react-storefront/global/src/services/SlatwalApiService";
import { useElementContext } from "@ultracommerce/react-storefront/global/src/contexts/ElementContext";
import { useServiceContext } from "@ultracommerce/react-storefront/global/src/contexts/ServiceContext";
import { bulkCalculatePrice } from "../../actions/cartActions";
import { useCustomFormatCurrency } from "../../hooks/useFormatCurrency";
import { useSelector } from "react-redux";
import {
  calMinDeliveryDate,
  calStepAndMinQuantity,
  modelNumberToSkuConfiguration,
} from "../../utils";
import { useStepQuantityInput } from "../../hooks/useStepQuantityInput";
import { useTranslation } from "react-i18next";
import { Overlay } from "@ultracommerce/react-storefront/global/src/components";

const OrderItem = ({ orderItem, index, setorderItems }) => {
  const { t } = useTranslation();
  const {
    CommonModule: { SelectedSkuConfiguration },
  } = useElementContext();
  const [formatCurrency] = useCustomFormatCurrency({});
  const { calculatedData } = orderItem;

  const { minDeliveryDate, leadTime } = useMemo(() => {
    return calMinDeliveryDate(
      calculatedData?.modelPriceInfo?.skuConfigurationPrice?.effectiveLeadTime
    );
  }, [
    calculatedData?.modelPriceInfo?.skuConfigurationPrice?.effectiveLeadTime,
  ]);
  const { stepQty, minQty } = calStepAndMinQuantity(
    orderItem.modifiers,
    orderItem.skuConfiguration
  );
  const inputProps = useStepQuantityInput({ stepQty, minQty });
  const [localQuantity, setLocalQuantity] = useState(orderItem.qty);

  useEffect(() => {
    setLocalQuantity(orderItem.qty);
  }, [orderItem.qty]);

  const [isSaveLoading, setSaveLoading] = useState(false);
  const handleChange = (value) => {
    setLocalQuantity(value);
  };

  const cancelQuantity = () => {
    handleChange(orderItem.qty);
  };

  const saveQuantity = () => {
    if (isSaveLoading) return;
    setSaveLoading(true);
    bulkCalculatePrice([
      {
        skuID: orderItem?.defaultSkuID,
        quantity: parseInt(localQuantity, 10),
        modelNumber: orderItem?.modelNumber,
      },
    ]).then((response) => {
      if (response.status === 200) {
        const calculatedData = response.data.prices[0];
        const { minDeliveryDate } = calMinDeliveryDate(
          calculatedData?.modelPriceInfo?.skuConfigurationPrice
            ?.effectiveLeadTime
        );
        setorderItems((prevState) => {
          const newState = [...prevState];
          newState[index] = {
            ...prevState[index],
            qty: parseInt(localQuantity, 10),
            calculatedData: calculatedData,
            requestedDeliveryDate: minDeliveryDate,
          };
          return newState;
        });
        setSaveLoading(false);
      }
    });
  };
  return (
    <tr key={index}>
      <td className="ps-1 pe-3 pb-2">
        <span className="font-weight-bold d-block">{orderItem?.productName.replace(/â€“/g, "-") || orderItem?.description}</span>
        <span className="small d-block my-2">
          Model Number: {calculatedData?.modelNumber}
        </span>
        <span className="small d-block my-2">
          {t("frontend.quote.leadTime", { leadTime })}
        </span>
        {orderItem.modifiers?.length && orderItem.skuConfiguration && (
          <SelectedSkuConfiguration
            modifiers={orderItem.modifiers}
            skuConfiguration={orderItem.skuConfiguration}
          />
        )}
      </td>
      <td className="py-3" style={{ minWidth: "100px" }}>
        <input
          min={minDeliveryDate}
          required
          type="date"
          className="form-control"
          style={{ borderRadius: "30px" }}
          name="requestedDeliveryDate"
          value={orderItem.requestedDeliveryDate}
          onChange={(e) => {
            setorderItems((prevState) => {
              const newState = [...prevState];
              newState[index] = {
                ...prevState[index],
                requestedDeliveryDate: e.target.value,
              };
              return newState;
            });
          }}
        />
      </td>
      <td className="py-3" style={{ minWidth: "100px" }}>
        <input
          type="text"
          className="form-control"
          style={{ borderRadius: "30px" }}
          name="customerMaterialNumber"
          value={orderItem.customerMaterialNumber}
          onChange={(e) => {
            setorderItems((prevState) => {
              const newState = [...prevState];
              newState[index] = {
                ...prevState[index],
                customerMaterialNumber: e.target.value,
              };
              return newState;
            });
          }}
        />
      </td>
      <td style={{ minWidth: "100px", paddingTop: '1.5rem' }}>
        {formatCurrency(
          calculatedData.modelPriceInfo
            .modelUnitPriceAfterDiscountAfterSurchargeAfterTax,
          calculatedData.modelPriceInfo.currencyCode
        )}
      </td>
      <td className="py-3">
        <Overlay
          active={isSaveLoading}
          spinner
          styles={{ overlay: (base) => ({ ...base, width: "100px" }) }}
        >
          <input
            {...inputProps}
            name="qty"
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            value={localQuantity}
            type="number"
            className="form-control ps-3"
            style={{ width: "100px", borderRadius: "30px" }}
            required
          />
        </Overlay>
        {parseInt(localQuantity) !== parseInt(orderItem.qty) &&
          !isSaveLoading && (
            <span>
              <button
                className="text-primary small text-underline linkit small pe-2 border-0 bg-transparent"
                onClick={() => saveQuantity()}
              >
                Save
              </button>
              <button
                className="text-primary small float-right text-underline linkit border-0 bg-transparent"
                title="Cancel"
                onClick={() => cancelQuantity()}
              >
                Cancel
              </button>
            </span>
          )}
      </td>
      <td style={{ paddingTop: '1.5rem', fontWeight: 'bold'}}>
        {formatCurrency(
          calculatedData.modelPriceInfo
            .totalModelPriceAfterDiscountAfterSurchargeAfterTax,
          calculatedData.modelPriceInfo.currencyCode
        )}
        <i
          className="bi bi-trash3 m-2"
          role="button"
          onClick={() => {
            setorderItems((prevState) =>
              prevState.filter((_, i) => i !== index)
            );
          }}
        ></i>
      </td>
    </tr>
  );
};

const QuickAddTab = ({
  orderID,
  getOrderInfo,
  isAddingProduct,
  setAddingProduct,
  setQuoteDetail,
}) => {
  const { addressZoneName } = useSelector((state) => state.userReducer);
  const typeaheadRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [input, setInput] = useState("");
  const [searchProduct, setSearchProduct] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  const [orderItems, setorderItems] = useState([]);
  // eslint-disable-next-line
  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);

  const filterBy = () => true;

  const addOrderItems = () => {
    if (isAddingProduct) return;
    setAddingProduct(true);
    getOrderInfo({ makeRequest: false });

    const skus = orderItems.map((item) => ({
      skuID: item.skuID,
      quantity: item.qty || item.quantity,
      customerMaterialNumber: item.customerMaterialNumber,
      plannedDeliveryDate: item.requestedDeliveryDate,
      requestedDeliveryDate: item.requestedDeliveryDate,
      currencyCode: item.calculatedData.modelPriceInfo.currencyCode,
      modelNumber: item.modelNumber,
    }));
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/addOrderItemsV2`,
      data: {
        orderID,
        skus,
        currencyCode: skus?.at(0)?.currencyCode,
      },
    })
      .then((response) => {
        if (
          response?.status === 200 &&
          response?.data?.failureActions.length === 0
        ) {
          setorderItems([]);
          toast.success("Items added successfully");
          getOrderInfo();
        } else {
          toast.error(getErrorMessage(response?.data?.failureActions));
          getOrderInfo({
            isFetching: false,
            makeRequest: false,
            isLoaded: true,
          });
        }
      })
      .finally(() => {
        setAddingProduct(false);
      });
  };

  const searchString = input.slice(0, 3);

  useEffect(() => {
    if (searchString.length !== 3) {
      return;
    }
    const source = axios.CancelToken.source();
    setisLoading(true);
    productService
      .searchTypeahead(searchString, "product", source, true)
      .then(async ({ data }) => {
        if ((data?.items || []).length > 0) {
          /**
           * We only need one product - the result here should be a list of fully qualified model codes
           */
          const item = data?.items.find(({ metadata }) =>
            metadata.skus.some(({ skuCode }) => skuCode === searchString)
          );
          item["sku"] =
            item.metadata.skus.find(
              ({ skuCode }) => skuCode === searchString
            ) || item.metadata.skus[0];
          setSearchProduct(data?.items[0]);
        } else {
          setSearchProduct(null);
        }
        setisLoading(false);
      });
  }, [productService, searchString]);

  useEffect(() => {
    if (!searchProduct || input.length < 3) {
      setProducts([]);
      return;
    }
    const { metadata, sku } = searchProduct;
    //@ts-ignore
    if (metadata)
      metadata.modifiers =
        metadata?.modifiers ||
        (addressZoneName === "US"
          ? metadata?.modifiersUS
          : metadata?.modifiersEU);
    const suggestions = [
      sku.skuCode,
      sku.skuCode,
      sku.skuCode,
      sku.skuCode,
      sku.skuCode,
    ]; // set up 5 suggestions
    const modelCodeMatchesCount = metadata.modifiers.reduce(
      (acc, modifier, idx) => {
        if (acc !== idx) return acc;
        const sectionOffset = metadata.modifiers
          .slice(0, idx)
          .reduce((acc, mod) => {
            acc = acc + mod.options[0]?.code.length;
            return acc;
          }, 3);
        const code = input
          .slice(sectionOffset, sectionOffset + modifier.options[0].code.length)
          .toLowerCase();
        const selectedCode = modifier.options.find((option, idx) => {
          return option.code.toLowerCase() === code;
        });

        if (selectedCode) {
          acc++;
          suggestions.forEach((suggest, suggestIdx) => {
            suggestions[suggestIdx] = suggest + selectedCode.code;
          });
        } else if (code.length) {
          const matchesOptions = modifier.options.filter((option) =>
            option.code.toLowerCase().startsWith(code)
          );
          if (matchesOptions.length) {
            acc++;
            suggestions.forEach((suggest, suggestIdx) => {
              suggestions[suggestIdx] =
                suggest +
                matchesOptions[suggestIdx % matchesOptions.length].code;
            });
          }
        }
        return acc;
      },
      0
    );
    metadata.modifiers.slice(modelCodeMatchesCount).forEach((modifier) => {
      suggestions.forEach((suggest, suggestIdx) => {
        const part = modifier.options[suggestIdx % modifier.options.length];
        suggestions[suggestIdx] = suggest + (part?.code || "");
      });
    });
    setProducts(
      [...new Set(suggestions)].map((label) => ({ label, metadata, sku }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, searchProduct]);

  const handleAddItem = (product, sku, searchTerm) => {
    // parse out the model code into skuConfiguration
    const skuConfiguration = modelNumberToSkuConfiguration(
      searchTerm,
      product.modifiers
    );

    const { minQty } = calStepAndMinQuantity(
      product.modifiers,
      skuConfiguration
    );
    const quantity = minQty;
    bulkCalculatePrice([
      {
        skuID: sku?.skuID,
        quantity,
        modelNumber: searchTerm,
      },
    ]).then((response) => {
      if (response.status === 200) {
        const calculatedData = response.data.prices[0];
        const { minDeliveryDate } = calMinDeliveryDate(
          calculatedData?.modelPriceInfo?.skuConfigurationPrice
            ?.effectiveLeadTime
        );

        setorderItems((prevState) => [
          ...prevState,
          {
            ...product,
            ...sku,
            modelNumber: searchTerm,
            skuConfiguration,
            qty: minQty,
            calculatedData: calculatedData,
            requestedDeliveryDate: minDeliveryDate,
          },
        ]);
      }
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        addOrderItems();
      }}
      className="quick-add-tab"
    >
      <div className="d-flex justify-content-between p-2 my-2 ">
        <div className="col-md-8 col-lg-4">
          <Typeahead
            className="border m-0 quickAdd-typeahead"
            filterBy={filterBy}
            id="postalCode"
            isLoading={isLoading}
            labelKey={(option) => `${option.label}`}
            onInputChange={setInput}
            options={products}
            minLength={3}
            placeholder="Search Model Codes..."
            emptyLabel={isLoading ? "Loading..." : undefined}
            ref={typeaheadRef}
            onChange={(selected) => {
              if (selected.length > 0) {
                handleAddItem(
                  selected[0].metadata,
                  selected[0].sku,
                  selected[0].label
                );
                typeaheadRef.current.clear();
              }
            }}
          />
        </div>
        <button
          className="btn btn-primary"
          type="submit"
          disabled={!orderItems.length || isAddingProduct}
        >
          {isAddingProduct ? (
            <span
              className="spinner-border spinner-border-sm p-2"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Add Items To List"
          )}
        </button>
      </div>
      <Overlay active={isAddingProduct} spinner>
        <div className="col-12 table-responsive">
          <table className="table w-100">
            {orderItems.length > 0 && (
              <thead>
                <tr>
                  <th style={{ width: "35%", minWidth: "35%" }}>Item</th>
                  <th>Customer Requested Date</th>
                  <th>Customer Material Number</th>
                  <th>Unit Price</th>
                  <th>Quantity</th>
                  <th>Line Total</th>
                </tr>
              </thead>
            )}
            <tbody>
              {orderItems.length > 0 ? (
                orderItems.map((orderItem, index) => {
                  return (
                    <OrderItem
                      key={orderItem.modelNumber}
                      index={index}
                      orderItem={orderItem}
                      setorderItems={setorderItems}
                      setQuoteDetail={setQuoteDetail}
                      orderID={orderID}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    Please search by model code to add items
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Overlay>
    </form>
  );
};

export default QuickAddTab;
