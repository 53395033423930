import { useEffect, useRef, useState, Fragment, useMemo } from "react";
import { useDebounce } from "react-use";
import { toast } from "react-toastify";
import {
  axios,
  getSdkURL,
  useElementContext,
  useServiceContext,
  getErrorMessage,
  useFormatDate,
} from "@ultracommerce/react-storefront/global";
import { useCustomFormatCurrency } from "../../hooks/useFormatCurrency";

const ProductList = ({ products, selectedSkus, setSelectedSkus }) => {
  const {
    CommonModule: { DraftOrderProductCard },
  } = useElementContext();
  return products.map((product, index) => {
    return (
      <DraftOrderProductCard
        key={index}
        product={product}
        selectedSkus={selectedSkus}
        setSelectedSkus={setSelectedSkus}
      />
    );
  });
};

const AddProductsTab = ({ orderID, getOrderInfo, isAddingProduct, setAddingProduct }) => {
  const [products, setProducts] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAllFetched, setAllFetched] = useState(false);
  const [formateDate] = useFormatDate();
  const [searchstring, setSearchstring] = useState("");
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [formatCurrency] = useCustomFormatCurrency({});
  const loadMoreItemRef = useRef();
  const observer = useRef();
  const { ProductService } = useServiceContext();
  const productService = useMemo(() => new ProductService(), [ProductService]);
  const {
    CommonModule: { SelectedSkuConfiguration },
  } = useElementContext();

  useEffect(() => {
    if (isFetching || isAllFetched || window.innerWidth < 768) return;
    const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting) {
        setCurrentPage((prevPage) => prevPage + 1); // Load next page of products
      }
    };
    // Initialize IntersectionObserver when component mounts
    observer.current = new IntersectionObserver(handleObserver, {
      threshold: 0.5,
    });

    // Attach observer to product container element
    if (loadMoreItemRef.current) {
      observer.current.observe(loadMoreItemRef.current);
    }

    return () => {
      // Remove observer when component unmounts
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [isAllFetched, isFetching]);

  useDebounce(
    () => {
      const source = axios.CancelToken.source();
      setIsFetching(true);

      productService
        .search(
          { searchTerm: searchstring, pageFrom: currentPage + 1, pageSize: 20 },
          "product",
          source
        )
        .then((data) => {
          if ((data.products || []).length > 0) {
            setProducts((prevProduct) =>
              prevProduct.length === 0
                ? data.products
                : [...prevProduct, ...data.products]
            );
          } else {
            setAllFetched(true);
          }
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    800,
    [currentPage, searchstring]
  );

  const renderProductRow = ({
    modifiers,
    skus,
    handleSelectedSkuRemove
  }) => {
    return skus.map((sku, index) => (
      <>
        <tr
          key={index}
          className="m-0 row align-items-center pt-2"
          style={{ background: "#fafafa" }}
        >
          <td className="col-3 py-2">
            <span>
              <b>Model Number</b>
              <br />
              {modifiers?.length
                ? sku?.calculatedData?.modelNumber
                : sku.skuCode}
            </span>
          </td>
          {sku?.customerMaterialNumber && (
            <td className="col-3 py-2">
              <span>
                <b>Customer Material Number</b>
                <br />
                {sku?.customerMaterialNumber}
              </span>
            </td>
          )}
          {sku?.requestedDeliveryDate && (
            <td className="col-4 py-2">
              <span>
                <b>Customer Requested Date</b>
                <br />
                {formateDate(sku?.requestedDeliveryDate)}
              </span>
            </td>
          )}
          <td className="col-2 py-2">
            <span className="fw-bold">Lead Time</span>
            <br />
            {sku?.calculatedData?.modelPriceInfo?.skuConfigurationPrice?.effectiveLeadTime} weeks
          </td>
          <td className="col-3 py-2">
            {" "}
            <span className="fw-bold">Price</span>
            <br />
            {formatCurrency(sku?.calculatedData?.modelPriceInfo?.modelUnitPriceAfterDiscountAfterSurchargeAfterTax, sku?.calculatedData?.modelPriceInfo?.currencyCode)}
          </td>
          <td className="col-3 py-2">
            <span className="fw-bold">Quantity</span>
            <br />
            {sku.quantity}
          </td>
          <td className="col-5 py-2">
            <span className="fw-bold">Total incl (Taxes and Surcharges)</span>
            <br />
            {formatCurrency(sku?.calculatedData?.modelPriceInfo?.totalModelPriceAfterDiscountAfterSurchargeAfterTax, sku?.calculatedData?.modelPriceInfo?.currencyCode)}
          </td>
        </tr>
        <tr
          style={{ background: "#fafafa" }}
          key={`config-` + index}
          className="m-0 row align-items-center pb-2 border-bottom"
        >
          <td className="col-11">
            {modifiers?.length && sku.skuConfiguration && (
              <SelectedSkuConfiguration
                modifiers={modifiers}
                skuConfiguration={sku.skuConfiguration}
              />
            )}
          </td>
          <td className="col-1">
            <i
              className="bi bi-trash3"
              style={{ cursor: isAddingProduct ? "" : "pointer" }}
              onClick={(e) => {
                if (
                  !isAddingProduct &&
                  window.confirm("Do you want to remove this item ?")
                ) {
                  console.log(sku,'sku');
                  handleSelectedSkuRemove(sku?.calculatedData?.modelNumber,skus);
                }
              }}
            ></i>
          </td>
        </tr>
      </>
    ));
  };

  const handleSelectedSkuRemove = (modelNumber,skus) => {
    setSelectedSkus((prevSelectedSkus) =>
      prevSelectedSkus
        .map((product) => ({
          ...product,
          skus: product.skus.filter(
            (sku) => sku.calculatedData.modelNumber !== modelNumber
          ),
        }))
        .filter((product) => product.skus.length > 0) // Remove products with no SKUs left
    );
  };

  const addOrderItems = () => {
    if (isAddingProduct) return;
    setAddingProduct(true);
    getOrderInfo({ makeRequest: false });
    const skus = [];
    selectedSkus.forEach((product) => {
      product.skus.forEach((sku) => {
        skus.push({
          skuID: sku.skuID,
          quantity: sku.quantity,
          customerMaterialNumber: sku.customerMaterialNumber,
          requestedDeliveryDate: sku.requestedDeliveryDate,
          plannedDeliveryDate: sku.requestedDeliveryDate,
          currencyCode: sku?.calculatedData?.modelPriceInfo?.currencyCode,
          modelNumber: sku?.calculatedData?.modelNumber
        });
      });
    });
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/addOrderItemsV2`,
      data: {
        orderID,
        skus,
        currencyCode: skus?.at(0)?.currencyCode,
        returnQuote: true,
        isQuote: true,
      },
    })
      .then((response) => {
        if (
          response?.status === 200 &&
          response?.data?.failureActions.length === 0
        ) {
          setSelectedSkus([]);
          getOrderInfo();
          toast.success("Items added successfully");
        } else {
          toast.error(getErrorMessage(response?.data?.failureActions));
          getOrderInfo({
            isFetching: false,
            makeRequest: false,
            isLoaded: true,
          });
        }
      })
      .finally(() => {
        setAddingProduct(false);
      });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        addOrderItems();
      }}
    >
      <div className="row justify-content-between py-2 my-2">
        <div className="col-12 col-sm-6 col-xl-4">
          <div className="input-group input-group-lg rounded-pill">
            <input
              className="form-control appended-form-control rounded-pill"
              type="text"
              placeholder="Search products to add"
              required=""
              onChange={(e) => {
                setSearchstring(e.target.value);
                setProducts([]);
                setCurrentPage(0);
                setAllFetched(false);
                setIsFetching(true);
                observer.current?.disconnect();
              }}
            />
            <button className="btn btn-link px-2" type="button">
              <i className="bi bi-search"></i>
            </button>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-xl-8 text-right">
          <button
            className="btn btn-primary btn-md position-relative"
            disabled={!selectedSkus.length || isAddingProduct}
            type="submit"
          >
            {isAddingProduct ? (
              <span
                className="spinner-border spinner-border-sm p-2"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Add Items To List"
            )}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-4">
          {products.length > 0 && (
            <ProductList
              products={products}
              setSelectedSkus={setSelectedSkus}
              selectedSkus={selectedSkus}
            />
          )}

          {products.length === 0 && !isFetching && (
            <div className="card p-3 my-2">
              <h5 className="text-danger">No Data Found</h5>
            </div>
          )}

          {isFetching && (
            <div className="card p-3 my-2">
              <h5 className="text-center">Loading....</h5>
            </div>
          )}

          <div ref={loadMoreItemRef}>
            {/* blank div to tap when doing infinite scroll */}
          </div>
        </div>

        <div className="col-md-6 col-lg-8 table-responsive">
          <table className="w-100">
            <thead></thead>
            <tbody>
              {selectedSkus.length > 0 && selectedSkus.map(
                ({ productName, modifiers, skus }, parentIndex) => {
                  console.log(productName)
                  if (!skus.length) {
                    return null;
                  }
                  return (
                    <Fragment key={parentIndex}>
                      <tr style={{ background: "#fafafa" }}>
                        <td>
                          <h5 className="my-2 px-2 mx-0">{productName}</h5>
                        </td>
                      </tr>
                      {renderProductRow({
                        modifiers,
                        skus,
                        productName,
                        handleSelectedSkuRemove
                      })}
                    </Fragment>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
};

export default AddProductsTab;
